import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'The best-in-class support',
        paragraph: 'A friendly, on-point and quick support that answers in 24 hours.'
    };

    const sectionFooter = {
        paragraph: 'If your needs are unordinary or you need a specific customization, just contact me privately. We will reach a deal!'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" id="support" />

                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-content">
                                    <p className="m-0 text-sm">
                                        If you prefer to contact the support via email, just send us an email! The average response time is 24 hours.
                                    </p>
                                    <p className="mt-12">
                                        <Button tag="a" color="primary" wideMobile href="mailto:m4nu.91@gmail.com" target="_blank">
                                            Email me!
                                        </Button>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-content">
                                    <p className="m-0 text-sm">
                                        If you prefer a real-time chat, you can just join our Discord server and meet a lot of customers and the developer.
                                    </p>
                                    <p className="mt-12">
                                        <Button tag="a" color="primary" wideMobile href="https://discord.gg/0ndGBjvogdY5SnIw" target="_blank">
                                            Join Discord!
                                        </Button>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <h4 className="ta-c">Any custom request?</h4>
                    <SectionHeader data={sectionFooter} className="center-content" />

                </div>
            </div>
        </section>
    );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;