import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'The perfect solution',
        paragraph: 'Explore which solution fits your needs the best:'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div id="product-comparison" className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" id="features" />

                    <table className="features-table">
                        <thead>
                            <th></th>
                            <th className="ta-c  tt-u">Basic</th>
                            <th className="ta-c  tt-u">Plus</th>
                            <th className="ta-c  tt-u">Professional</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Binary diffing and small patches building</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c">N/A</td>
                            </tr>
                            <tr>
                                <td>Patches compression</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c">N/A</td>
                            </tr>
                            <tr>
                                <td>Corrupted files repairing (replace the whole file)</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c">N/A</td>
                            </tr>
                            <tr>
                                <td>Chunk-based repairing (replace only the damaged portion)</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Fast content defined chunking</td>
                                <td className="ta-c">N/A</td>
                                <td className="ta-c">N/A</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Single bundle deployment</td>
                                <td className="ta-c">N/A</td>
                                <td className="ta-c">N/A</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Bundle compression with Zstandard</td>
                                <td className="ta-c">N/A</td>
                                <td className="ta-c">N/A</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Self hosting</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>FTP deployment tool</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Self updating</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Unity integration</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>WPF integration</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>WinForms integration</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>CI/CD tools</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>CI/CD configuration for major platforms</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Sequential HTTP downloader</td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c">N/A</td>
                            </tr>
                            <tr>
                                <td>Multithreaded HTTP downloader</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c">N/A</td>
                            </tr>
                            <tr>
                                <td>Parallel chunk-based HTTP downloader</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Dropbox HTTP downloader</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Extensive test coverage</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                            <tr>
                                <td>Source code access</td>
                                <td className="ta-c"></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                                <td className="ta-c"><span className="has-feature-mark"></span></td>
                            </tr>
                        </tbody>
                    </table>


                    <table className="features-table features-links">
                        <tr>
                            <td>
                                <h4 className="tt-u">Basic</h4>
                            </td>
                            <td className="ta-r">
                                <Button tag="a" color="primary" wideMobile href="http://u3d.as/hSv" target="_blank">
                                    Asset Store
                                </Button>
                                <Button tag="a" color="primary" wideMobile href="https://manhunter.itch.io/patch-ultimate-patching-system" target="_blank">
                                    itch.io
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4 className="tt-u">Plus</h4>
                            </td>
                            <td className="ta-r" colSpan={2}>
                                <Button tag="a" color="primary" wideMobile href="http://u3d.as/jSG" target="_blank">
                                    Asset Store
                                </Button>
                                <Button tag="a" color="primary" wideMobile href="https://manhunter.itch.io/patch-ultimate-patching-system" target="_blank">
                                    itch.io
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4 className="tt-u">Professional</h4>
                            </td>
                            <td className="ta-r" colSpan={2}>
                                <Button tag="a" color="dark" wideMobile>
                                    Coming soon...
                                </Button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </section>
    );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;