import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

const Testimonial = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Showcase',
        paragraph: 'PATCH is successfully used in production by multiple games and applications.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" id="showcase" />
                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-rigth" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/potbs_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Vision Online Games</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://burningsea.com/en" target="_blank">Pirates of the Burning Sea</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-rigth" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/robocraft2_logo.webp')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Freejam</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://www.robocraft2.com/" target="_blank">Robocraft 2</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-rigth" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/ethereal_logo.webp')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Undying Games</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://www.undyinggames.com/" target="_blank">Ethereal: Clash of Souls</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-rigth" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/deepspace_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Deepspace</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://deepspace.game/" target="_blank">Deepspace</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/minesim_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">APrograming</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://www.ap-minesim.com/" target="_blank">MineSim</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/aag_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Three Flip Studios</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="http://arcade.armedandgelatinous.com/" target="_blank">Armed and Gelatinous</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/tankigon_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">TANKIGON</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://tankigon.com/" target="_blank">TANKIGON</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/gol_logo.webp')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Paul Brothers GmbH</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://www.glimpse-luna.com/" target="_blank">A Glimpse of Luna</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/te4_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Mana Games</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://www.managames.com/Forum/topic15-30551.php" target="_blank">Tennis Elbow 4</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-rigth" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/tillaris_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">AEGIS</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://tillaris.com/" target="_blank">Tillaris</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/aog_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Armies Of Ganivar, LLC</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="https://armiesofganivar.com/" target="_blank">Armies Of Ganivar</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner" style={{ background: "none" }}>
                                <div className="tiles-item-image">
                                    <Image src={require('./../../assets/images/hexarchia_logo.png')} />
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider ta-c">
                                    <span className="testimonial-item-name text-color-high">Overdroid S.R.L.</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        <a href="http://www.hexarchia.com/" target="_blank">Hexarchia</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="center-content">...and many more!</div>
                </div>
            </div>
        </section>
    );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;