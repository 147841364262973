import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesFaq from '../components/sections/FeaturesFaq';
import FeaturesSupport from '../components/sections/FeaturesSupport';
import FeaturesTable from '../components/sections/FeaturesTable';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {

    return (
        <>
            <Hero className="illustration-section-01" />
            <FeaturesTiles />
            <FeaturesSplit invertMobile imageFill />
            <FeaturesTable />
            <FeaturesSupport />
            <FeaturesFaq invertMobile imageFill />
            <Testimonial topDivider />
        </>
    );
}

export default Home;