import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
    className,
    ...props
}) => {

    const classes = classNames(
        'footer-nav',
        className
    );

    return (
        <nav
            {...props}
            className={classes}
        >
            <ul className="list-reset">
                <li>
                    <a href="mailto:m4nu.91@gmail.com" target="_blank">Email</a>
                </li>
                <li>
                    <a href="http://mhlab.tech/page/about-me/" target="_blank">About us</a>
                </li>
                <li>
                    <a href="https://discord.gg/0ndGBjvogdY5SnIw" target="_blank">Support</a>
                </li>
            </ul>
        </nav>
    );
}

export default FooterNav;