import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Aren\'t you convinced yet?',
        paragraph: 'It\'s fine to have some questions!'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" id="faq" />

                    <div className="center-content-mobile reveal-from-left">
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                            FAQ
                        </div>
                        <h3 className="mt-0 mb-12">
                            How does it build updates for my game?
                        </h3>
                        <p className="m-0">
                            It generates a binary patch by computing differences between two versions of your game,
                            then it collects metadata for the update which is packaged and compressed.
                        </p>
                        <hr />
                    </div>
                    <div className="center-content-mobile reveal-from-right mt-12 ta-r">
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                            FAQ
                        </div>
                        <h3 className="mt-0 mb-12">
                            How does it apply updates to my game?
                        </h3>
                        <p className="m-0">
                            It checks if all files are valid. If not, they are repaired. Then the shortest path for
                            the latest version is evaluated and the updates are downloaded and applied.
                        </p>
                        <hr />
                    </div>

                    <div className="center-content-mobile reveal-from-left mt-12">
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                            FAQ
                        </div>
                        <h3 className="mt-0 mb-12">
                            What does it require on server side?
                        </h3>
                        <p className="m-0">
                            You just need a simple web server that can serve files over HTTP. It needs to expose direct URLs to files.
                            Read the documentation to see some examples.
                        </p>
                        <hr />
                    </div>
                    <div className="center-content-mobile reveal-from-right mt-12 ta-r">
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                            FAQ
                        </div>
                        <h3 className="mt-0 mb-12">
                            How can I localize the content?
                        </h3>
                        <p className="m-0">
                            Localization is obtained by just implementing an interface in C# and injecting it into the context.
                            The documentation contains a dedicated section on this topic. In the repository you can find multiple translations
                            made by some customers.
                        </p>
                        <hr />
                    </div>

                    <div className="center-content-mobile reveal-from-left mt-12">
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                            FAQ
                        </div>
                        <h3 className="mt-0 mb-12">
                            Does it work on mobile platforms?
                        </h3>
                        <p className="m-0">
                            No, it currently does not officially support mobile platforms.
                        </p>
                        <hr />
                    </div>
                    <div className="center-content-mobile reveal-from-right mt-12 ta-r">
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                            FAQ
                        </div>
                        <h3 className="mt-0 mb-12">
                            Do I need to store all my game builds on the server?
                        </h3>
                        <p className="m-0">
                            No, it is not needed. You can store just a single, initial build if you like. But I advice against it.
                            In the documentation I explained some nice strategies about this topic!
                        </p>
                    </div>

                </div>
            </div>
        </section>
    );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;